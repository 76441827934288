<template>
  <div class="page">
    <el-form :model="formInfo" label-width="140px" :rules="rules" ref="formInfo">
      <div class="buttonbc">
        <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
          <img src="@/assets/back.png" alt="">
        </el-button>
      </div>
      <h4>客户基础信息</h4>
      <el-row type="flex" justify="space-between" style="margin-right: 20px;">
        <el-col :span="8">
          <el-form-item label="客户名称" prop="name">
            <el-input :maxlength="100" v-model.trim="formInfo.name" placeholder="请填写客户名称" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="代理名称" prop="agent_person">
            <el-input :maxlength="50" v-model.trim="formInfo.agent_person" placeholder="请填写代理名称" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系人名称" prop="contacts_person">
            <el-input :maxlength="50" v-model.trim="formInfo.contacts_person" placeholder="请填写联系人名称"
              clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" style="margin-right: 20px;">
        <el-col :span="8">
          <el-form-item label="联系电话" prop="contacts_phone">
            <el-input :maxlength="20" v-model.trim="formInfo.contacts_phone" placeholder="请填写联系电话" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属销售人员" prop="sell_name">
            <en-sale-search :showBtn="false" placeholder="请选择销售人员" v-model.trim="formInfo.sell_name"
              @change="handleSelectSell" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="客户地址" prop="customer_address" style="margin-right: 20px;">
        <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 4 }" placeholder="请填写客户地址" :maxlength="100"
          v-model.trim="formInfo.customer_address"></el-input>
      </el-form-item>
      <h4>开卡方式</h4>
      <el-form-item label="开卡方式" prop="open_card_type">
        <el-radio-group v-model="formInfo.open_card_type">
          <el-radio :label="1">后台人工核销开卡</el-radio>
          <el-radio :label="2">自动核销开卡</el-radio>
        </el-radio-group>
        <el-tooltip style="max-width:200px;" effect="dark" placement="top">
          <label class="cursor-pointer"><i class="el-icon-warning-outline"></i></label>
          <div slot="content">
            1、启用后，系统将会自动对移动开卡商城前台创建的销售开卡单进行核销并开卡的操作。<br />
            2、自动核销开卡的单据在企业后台也可进行手动核销开卡操作。<br />
            3、单据在自动核销开卡执行前，被企业手动进行核销或开卡操作后，对应的开卡或核销操作，将会变更为手动操作。
          </div>
        </el-tooltip>
      </el-form-item>
      <h4>账期及账户信息</h4>
      <el-form-item label="账期类型" prop="account_period">
        <el-radio-group v-model="formInfo.account_period" @change="changeAccountPeriod">
          <el-radio :label="0">无账期</el-radio>
          <el-radio v-if="formInfo.account_period == 1" :label="1">有账期</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="formInfo.account_period == 1" label="账期信用额度" prop="account_period_credit_limit">
        <el-input style="width:200px" type="text" v-model.trim="formInfo.account_period_credit_limit"
          :disabled="formInfo.id != ''">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="初始预存款" prop="initial_deposit">
        <el-input style="width:200px" type="text" :maxlength="10" v-model.trim="formInfo.initial_deposit"
          :disabled="id !== '' && showDisabled">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="formInfo.account_period == 1" label="账期结算方式" prop="client_addr">
        {{ formInfo.account_period_settlement_method == 1 ? '按天' : '按月' }}
      </el-form-item>
      <el-form-item v-if="formInfo.account_period == 1" label="结算周期" prop="account_period_settlement_cycle">
        <el-input :disabled="formInfo.id != ''" style="width:200px" type="text" :maxlength="100"
          v-model.trim="formInfo.account_period_settlement_cycle">
          <template slot="append">{{ formInfo.account_period_settlement_method == 1 ? '天' : '月' }}</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="formInfo.account_period == 1" label="账期可延期天数" prop="account_period_extended_day">
        <el-input :disabled="formInfo.id != ''" style="width:200px" type="text" :maxlength="100"
          v-model.trim="formInfo.account_period_extended_day">
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <el-form-item label="开卡账户余额预警" prop="balance_warning">
        <el-switch v-model="formInfo.balance_warning" :active-value="1" :inactive-value="0">
        </el-switch>
        <span style="margin:0 10px">余额预警值为：{{ (formInfo.balance_warning_amount || 0) | unitPrice('￥')
          }}</span><el-button type="text" @click="editWarningMoney">修改</el-button>
      </el-form-item>
      <h4>项目信息</h4>
      <el-form-item label="是否为定制项目" prop="customized_project">
        <el-radio-group v-model="formInfo.customized_project">
          <el-radio :label="0">否</el-radio>
          <el-radio :label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>

      <div v-if="formInfo.customized_project == 1" style="padding:0 10px;width:900px">
        <div class="bank-list" v-for="(  item, index  ) in   formInfo.customer_customized_project_dtolist  "
          :key="index">
          <el-form-item label="来源应用" :prop="`customer_customized_project_dtolist[${index}].shop_type`"
            :rules="rules.shop_type">
            <el-select v-model="item.shop_type" placeholder="请选择" @change="handleSelectCategory(item.shop_type, index)">
              <el-option v-for="storeType in storeTypeList" :key="storeType.value" :label="storeType.name"
                :value="storeType.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商城名称" :prop="`customer_customized_project_dtolist[${index}].shop_id`"
            :rules="rules.shop_id">
            <el-select filterable @focus="handleShopFocus(item.shop_type, index)" v-model.trim="item.shop_id"
              placeholder="请选择" @change="selectshopName">
              <el-option v-for="  shops   in   item.storeList  " :key="shops.shop_id" :label="shops.shop_name"
                :disabled="shops.disabled" :value="shops.shop_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目总金额" :prop="`customer_customized_project_dtolist[${index}].total_amount`"
            :rules="rules.total_amount">
            <el-input style="width: 200px;" type="text" v-model.trim="item.total_amount"
              placeholder="请填写项目总金额"></el-input>
          </el-form-item>
          <el-form-item label="首付款" :prop="`customer_customized_project_dtolist[${index}].down_payment`"
            :rules="rules.down_payment">
            <el-input style="width: 200px;" type="text" v-model.trim="item.down_payment" placeholder="请填写首付款"
              :disabled="item.isSettlement"></el-input>
          </el-form-item>
          <el-form-item label="结算方式" prop="settlement_method">
            <el-radio-group v-model="item.settlement_method">
              <el-radio :label="1">按次</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="项目结算次数" :prop="`customer_customized_project_dtolist[${index}].settlement_times`"
            :rules="rules.settlement_times">
            <el-input-number size="small" v-model.trim="item.settlement_times" @change="handleChange(item, index)"
              :min="item.isDisabled" :max="20"></el-input-number>次
          </el-form-item>

          <el-form-item label="结算日期及金额">
            <el-table style="width: 600px" border stripe :data="item.customer_customized_project_settlement_dtolist">
              <el-table-column :key="'code'" label="序号" width="60">
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="date" label="结算日期" :render-header="addRedStar" width="200">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`customer_customized_project_dtolist[${index}].customer_customized_project_settlement_dtolist[${scope.$index}].settlement_date`"
                    :rules="rules.settlement_date">
                    <el-date-picker style="width: 140px" value-format="timestamp"
                      v-model.trim="scope.row.settlement_date" type="date" placeholder="请选择日期"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column prop="money" label="结算金额（元）" :render-header="addRedStar">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="`customer_customized_project_dtolist[${index}].customer_customized_project_settlement_dtolist[${scope.$index}].settlement_amount`"
                    :rules="rules.settlement_amount">
                    <el-input type="text" v-model.trim="scope.row.settlement_amount" placeholder="请填写结算金额"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="结算提醒">
            <el-switch v-model="item.settlement_notify_flag" :active-value="1" :inactive-value="0">
            </el-switch>
            <span style="margin:0 10px">提示：开启后，系统将在结算日期前3天，以短信形式通知这里设置的提醒手机号用户及时进行结算回款。</span>
          </el-form-item>
          <el-form-item v-if="item.settlement_notify_flag == 1" label="提醒手机号"
            :prop="`customer_customized_project_dtolist[${index}].settlement_notify_mobile`"
            :rules="rules.settlement_notify_mobile">
            <el-input style="width: 200px" type="text" v-model.trim="item.settlement_notify_mobile" placeholder="请填写手机号"
              maxlength="11"></el-input>
          </el-form-item>

          <span v-if="formInfo.customer_customized_project_dtolist.length > 1" class="el-icon-error delBank"
            @click="delBank(index)"></span>
        </div>
        <el-button style="margin:10px 10px 30px 16px;"
          :disabled="formInfo.customer_customized_project_dtolist.length == 3" size="small" type="primary"
          @click="addBank">新增项目</el-button>
        <span>提示：客户项目最多可添加3个。</span>
      </div>


      <h4>合同信息</h4>
      <el-form-item label="合同文件上传" prop="client_addr">
        <el-upload style="width:450px" :show-file-list="false" accept=".doc, .docx, .pdf" class="upload-demo"
          :action="`${MixinUploadApi}?scene=file`" :on-success="uploadSuccess">
          <el-button :disabled="formInfo.contract_documents != '' && formInfo.contract_documents != null" size="small"
            type="primary">文件上传</el-button>
          <div slot="tip" class="el-upload__tip" style="display: inline-block;margin-left: 10px;">
            提示：仅允许上传doc、docx、PDF格式文件，数量为1。</div>
        </el-upload>
        <p>
          <span v-if="formInfo.contract_documents !== ''">{{ formInfo.contract_document_name }}</span>
          <el-button v-if="this.id && formInfo.contract_documents" type="text" @click="fileDownload">下载</el-button>
          <el-button v-if="formInfo.contract_documents" type="text" @click="handleRemove">删除</el-button>
        </p>
      </el-form-item>
      <el-form-item label="图片凭证上传" prop="client_addr">
        <el-upload accept=".jpg, .png, .jpeg" list-type="picture-card" class="upload-demo"
          :action="`${MixinUploadApi}?scene=goods`" :limit="10" :on-success="imgUploadSuccess" :show-file-list="false"
          :file-list="fileList">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">提示：请上传jpg、png、jpeg格式的图片文件，最多允许上传10张。上传的图片可拖拽调整位置。</div>
        </el-upload>
      </el-form-item>
      <el-form-item prop="invoiceType">
        <ul class="el-upload-list el-upload-list--picture-card">
          <vuedraggable class="wrapper" v-model="fileList" chosen-class="chosen" force-fallback="true" group="people"
            animation="100">

            <li class="el-upload-list__item is-success animated" v-for="(  item, index  ) in   fileList  " :key="index"
              style="margin-right:10px;width:80px;height:80px;">
              <img :src="item.url" alt="" class="el-upload-list__item is-success">
              <!--   <i class="el-icon-close"></i> -->
              <span class="el-upload-list__item-actions">
                <!-- 预览 -->
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(item)">
                  <i class="el-icon-zoom-in"></i>
                </span>
                <!-- 删除 -->
                <span class="el-upload-list__item-delete" @click="imageHandleRemove(index)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </li>
          </vuedraggable>
        </ul>
      </el-form-item>
      <h4>开票信息</h4>
      <el-form-item label="发票信息设置" prop="invoice_type">
        <el-radio-group v-model="formInfo.invoice_info.invoice_type" @change="invoiceTypeChange">
          <el-radio :label="1">普通电子发票</el-radio>
          <el-radio :label="3">普通纸质发票</el-radio>
          <el-radio :label="2">增值税专用发票-电子版</el-radio>
          <el-radio :label="4">增值税专用发票-纸质版</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发票抬头" prop="invoice_info.invoice_title" :rules="rules.invoice_title" style="width:500px">
        <el-input v-model.trim="formInfo.invoice_info.invoice_title" placeholder="请填写发票抬头" :maxlength="100"
          clearable></el-input>
      </el-form-item>
      <el-form-item label="企业税号" prop="invoice_info.tax_no" :rules="rules.tax_no" style="width:500px">
        <el-input v-model.trim="formInfo.invoice_info.tax_no" placeholder="请填写企业税号" :maxlength="100"
          clearable></el-input>
      </el-form-item>
      <el-form-item>
        <span>如果您的营业执照是三证合一或五证合一的证照，请填写最新的营业执照编号。</span>
      </el-form-item>
      <el-form-item style="width:500px" v-if="[2, 4].includes(formInfo.invoice_info.invoice_type)" label="开户银行"
        prop="invoice_info.bank_name" :rules="rules.bank_name">
        <el-input v-model.trim="formInfo.invoice_info.bank_name" placeholder="请填写开户银行名称" :maxlength="100"
          clearable></el-input>
      </el-form-item>
      <el-form-item style="width:500px" v-if="[2, 4].includes(formInfo.invoice_info.invoice_type)" label="开户账号"
        prop="invoice_info.bank_account" :rules="rules.bank_account">
        <el-input v-model.trim="formInfo.invoice_info.bank_account" placeholder="请填写开户银行账号" :maxlength="100"
          clearable></el-input>
      </el-form-item>


      <el-form-item style="width:500px" v-if="[2, 4].includes(formInfo.invoice_info.invoice_type)" label="注册地址"
        prop="invoice_info.reg_address" :rules="rules.reg_address">
        <el-input v-model.trim="formInfo.invoice_info.reg_address" placeholder="请填写注册地址" :maxlength="100"
          clearable></el-input>
      </el-form-item>
      <el-form-item style="width:500px" v-if="[2, 4].includes(formInfo.invoice_info.invoice_type)" label="注册电话"
        prop="invoice_info.reg_tel" :rules="rules.reg_tel">
        <el-input v-model.trim="formInfo.invoice_info.reg_tel" placeholder="请填写公司电话" :maxlength="20"
          clearable></el-input>
      </el-form-item>
      <el-form-item style="width:500px" v-if="[1, 2].includes(formInfo.invoice_info.invoice_type)" label="邮箱地址"
        prop="invoice_info.email" :rules="rules.email">
        <el-input v-model.trim="formInfo.invoice_info.email" placeholder="请填写邮箱地址" :maxlength="100"
          clearable></el-input>
      </el-form-item>
      <h3 v-if="[3, 4].includes(formInfo.invoice_info.invoice_type)" style="margin-left:30px">发票寄送地址：</h3>
      <el-form-item style="width:500px" v-if="[3, 4].includes(formInfo.invoice_info.invoice_type)" label="收件人姓名"
        prop="invoice_info.receive_name" :rules="rules.receive_name">
        <el-input v-model.trim="formInfo.invoice_info.receive_name" placeholder="请输入收件人姓名" :maxlength="50"
          clearable></el-input>
      </el-form-item>
      <el-form-item style="width:500px" v-if="[3, 4].includes(formInfo.invoice_info.invoice_type)" label="手机号"
        prop="invoice_info.receive_phone" :rules="rules.receive_phone">
        <el-input v-model.trim="formInfo.invoice_info.receive_phone" placeholder="请输入收件人手机号" :maxlength="11"
          clearable></el-input>
      </el-form-item>

      <el-form-item v-if="[3, 4].includes(formInfo.invoice_info.invoice_type)" style="margin-bottom:22px" label="收货地址"
        prop="invoice_info.receive_county_id" :rules="rules.receive_county_id">
        <el-select @change="changeProvince('province')" size="small" class="choose-machine center"
          v-model="formInfo.invoice_info.receive_province_id" placeholder="省/直辖市"
          style="width: 100px; margin-right: 5px" clearable>
          <el-option v-for="(  item, index  ) in   this.provinceList  " :label="item.name" :value="item.id"
            :key="index">
          </el-option>
        </el-select>

        <el-select @change="changeProvince('city')" size="small" class="choose-machine center"
          v-model="formInfo.invoice_info.receive_city_id" placeholder="城市/地区" style="width: 100px; margin-right: 5px"
          clearable>
          <el-option v-for="(  item, index  ) in   this.cityList  " :label="item.name" :value="item.id" :key="index">
          </el-option>
        </el-select>

        <el-select @change="changeProvince('county')" size="small" class="choose-machine center"
          v-model="formInfo.invoice_info.receive_county_id" placeholder="县/区" style="width: 100px; margin-right: 5px"
          clearable>
          <el-option v-for="(  item, index  ) in   this.countyList  " :label="item.name" :value="item.id" :key="index">
          </el-option>
        </el-select>

        <el-select size="small" @change="changeProvince('town')" class="choose-machine center"
          v-model="formInfo.invoice_info.receive_town_id" placeholder="乡镇/街道" style="width: 100px; margin-right: 5px"
          clearable>
          <el-option v-for="(  item, index  ) in   this.townList  " :label="item.name" :value="item.id" :key="index">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="width: 500px;" v-if="[3, 4].includes(formInfo.invoice_info.invoice_type)" label="详细地址"
        prop="invoice_info.receive_address" :rules="rules.receive_address">
        <el-input placeholder="请输入详细地址" :maxlength="100" type="textarea"
          v-model.trim="formInfo.invoice_info.receive_address" :rows="2" clearable></el-input>
      </el-form-item>
      <el-form-item style="padding-bottom: 50px;">
        <el-button type="primary" @click="save">保 存</el-button>
      </el-form-item>

    </el-form>
    <!-- 新建申请 -->
    <el-dialog-x-dialog title="预警值设置" :visible.sync="activeVisible" width="35%" append-to-body
      :close-on-press-escape="false" ref="elDialogXdialog">
      <div style="padding:10px">
        <el-form :model="applicationForm" ref="applicationForm" :rules="applicationFormRule" label-width="130px"
          size="small">
          <span v-if="formInfo.account_period == 0">提示：当移动端客户账户余额低于该值时，系统将会持续3天（含当天），在每天的09:00时给开卡人员发送一次短信通知提醒。</span>
          <span v-else>提示：当移动端客户账户余额低于该值时，系统将会给开卡人员发送一次短信通知提醒。</span>
          <el-form-item style="margin-top:10px" label="预警值设置" prop="balance_warning_amount">
            <el-input style="width:200px" type="text" v-model.trim="applicationForm.balance_warning_amount" clearable>
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="预警手机号" prop="balance_warning_mobile">
            <el-input style="width:200px" type="text" :maxlength="11"
              v-model.trim="applicationForm.balance_warning_mobile" clearable>
            </el-input>
          </el-form-item>
          <el-form-item style="text-align:right">
            <el-button @click="activeVisible = false" size="small">取 消</el-button>
            <el-button type="primary" size="small" @click="transferConfirm">确 定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog-x-dialog>
    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable';
import * as API_goods from "@/api/goods";
import { getShopExtAuth } from "@/api/order";
import { RegExp } from '@/../ui-utils'
import * as API_settingCustomer from '@/api/settingCustomer';
import * as API_Shop from "@/api/shop";
import mixins2 from "@/views/tools/sale-card-open/modules/saleOrderRefundMixins";
import mixin from "./mixin";
import { Foundation } from '@/../ui-utils';
export default {
  name: "editCustomerProfile",
  mixins: [mixins2, mixin],
  components: {
    vuedraggable,
  },
  data () {
    return {
      showDisabled: true,
      pickerOptions: {
        disabledDate: time => {
          return time.getTime() <= new Date().getTime() - 86400000;//选择今天以及今天之后的日期 
        }
      },
      storeTypeList: [],
      id: '',
      //发票寄送地址
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      files: [],//合同凭证回显
      fileList: [],//图片凭证
      dialogImageUrl: '',
      dialogVisible: false,
      isCompanyName: '',//客户名称是否重复
      formInfo: {
        name: "",//客户名称
        agent_person: "",//代理名称
        contacts_person: "",//联系人名称
        contacts_phone: "",//联系电话
        sell_name: "",//所属销售人员
        customer_address: "",//客户地址
        open_card_type: 1,//开卡方式 1:后台人工核销开卡 2：
        account_period: 0,//账期类型 0：无账期 1：有账期
        account_period_credit_limit: '',//账期信用额度
        initial_deposit: '',//初始预存款
        account_period_settlement_method: 1,//结算方式
        account_period_settlement_cycle: '',//结算周期
        account_period_extended_day: '',//账期可延期天数
        balance_warning: 0,//是否开启预警
        balance_warning_amount: '',//预警值
        balance_warning_mobile: '',//预警手机号
        customized_project: 0,//是否定制项目
        customer_customized_project_dtolist: [
          {
            shop_type: '',//来源应用
            shop_id: '',//商城名称
            total_amount: '',//项目总金额
            down_payment: '',//首付款
            settlement_method: 1,//结算方式
            settlement_times: 1,//项目结算次数
            isDisabled: 1,//默认最小结算次数
            storeList: [],//所选应用列表商城
            customer_customized_project_settlement_dtolist: [
              {
                settlement_date: '',
                settlement_amount: ''
              }
            ],//结算日期及金额
            settlement_notify_flag: '',//结算提醒
            settlement_notify_mobile: '',//结算提醒手机号
          }
        ],//定制项目
        contract_documents: "",//合同文件路径
        contract_document_name: "",//合同文件名称
        image_voucher: '',//图片凭证
        //开票信息
        invoice_info: {
          invoice_type: 1,//发票类型 1:普通电子发票 2:增值税电子发票 3:普通纸质发票 4:增值税纸质发票
          invoice_title: '',
          tax_no: '',
          bank_name: '',
          bank_account: '',
          reg_address: '',//注册地址
          reg_tel: '',//注册电话
          email: '',//邮箱地址
          receive_name: '',//收件人姓名
          receive_phone: '',//收件人手机号
          receive_county_id: '',//收货地址
          receive_address: '',//收货详细地址
          receive_town_id: '',
          receive_town: '',
        },
      },
      rules: {
        name: [
          {
            required: true,
            message: "请填写客户名称",
            trigger: ["blur", "change"],
          },
          {
            validator: async (rule, value, callback) => {
              if (this.formInfo.id && this.isCompanyName != value) {//编辑
                const res = await API_settingCustomer.getByNames(value)
                if (res) callback(new Error('客户名称已存在'))
                else callback()

              } else if (!this.formInfo.id) {//添加
                const res = await API_settingCustomer.getByNames(value)
                if (res) callback(new Error('客户名称已存在'))
                else callback()
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        contacts_person: [
          {
            required: true,
            message: "请填写联系人名称",
            trigger: ["blur", "change"],
          },
        ],
        contacts_phone: [
          {
            required: true,
            message: "请填写联系电话",
            trigger: ["blur", "change"],
          }
        ],
        sell_name: [
          {
            required: true,
            message: "请选择销售人员",
            trigger: ["blur", "change"],
          }
        ],
        customer_address: [
          {
            required: true,
            message: "请填写客户地址",
            trigger: ["blur", "change"],
          }
        ],
        initial_deposit: [//初始预存款
          {
            validator: (rule, value, callback) => {
              if (value) {
                const money = /^\d+(\.\d{1,2})?$/
                if (value > 1000000) {
                  callback(new Error('输入最大不超100万'))
                } else if (!RegExp.money.test(value) || !money.test(value)) {
                  callback(new Error("请输入正确的金额"));
                } else {
                  callback()
                }
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        shop_type: [
          {
            required: true,
            message: "请选择商城类型",
            trigger: ["blur", "change"],
          }
        ],
        shop_id: [
          {
            required: true,
            message: "请选择商城",
            trigger: ["blur", "change"],
          }
        ],
        total_amount: [
          {
            required: true,
            message: "请填写项目总金额",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (value) {
                const parent = Number(rule.field.split('[')[1].charAt(0))
                let resData = this.formInfo.customer_customized_project_dtolist[parent]

                const money = /^\d+(\.\d{1,2})?$/
                if (!RegExp.money.test(value) || !money.test(value) || value == 0) {
                  callback(new Error("请输入正确的金额"));
                }
                else if (value > 100000000) {
                  callback(new Error('最大值不超1亿'))
                } else if (resData.isSettlement && value < resData.original_total_amount) {
                  callback(new Error('不可低于当前项目总金额'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        down_payment: [//首付款
          {
            validator: (rule, value, callback) => {
              if (value) {
                const money = /^\d+(\.\d{1,2})?$/
                if (value > 50000000) {
                  callback(new Error('输入最大不超5000万'))
                } else if (!RegExp.money.test(value) || !money.test(value) || value == 0) {
                  callback(new Error("请输入正确的金额"));
                } else {
                  callback()
                }
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        settlement_times: [
          {
            required: true,
            message: "请填写结算次数",
            trigger: ["blur", "change"],
          },
        ],
        settlement_notify_mobile: [
          {
            required: true,
            message: "请填写预警手机号",
            trigger: ["blur", "change"],
          }, {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error('手机号码格式有误！'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        settlement_date: [
          {
            required: true,
            message: "请选择结算日期",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              const parent = Number(rule.field.split('[')[1].charAt(0))
              let arr = []
              this.formInfo.customer_customized_project_dtolist[parent].customer_customized_project_settlement_dtolist.forEach(item => {
                if (item.settlement_date == value) {
                  arr.push(item)
                }
              })
              //同一项目中不允许重复，不同项目间允许重复
              if (arr.length > 1) {
                callback(new Error("当前日期已存在"));
              } else {
                callback()
              }
            }
          }
        ],
        settlement_amount: [
          {
            required: true,
            message: "请填写结算金额",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              const parent = Number(rule.field.split('[')[1].charAt(0))
              const total_amount = this.formInfo.customer_customized_project_dtolist[parent].total_amount;//项目总金额
              const down_payment = this.formInfo.customer_customized_project_dtolist[parent].down_payment;//首付款
              const total = Foundation.subtraction(total_amount, down_payment) //项目总金额-首付款
              const totalMonery = this.formInfo.customer_customized_project_dtolist[parent].customer_customized_project_settlement_dtolist.map(res => {
                return Number(res.settlement_amount || 0)
              })

              const money = /^\d+(\.\d{1,2})?$/
              if (!RegExp.money.test(value) || !money.test(value) || value == 0) {
                callback(new Error("请输入正确的金额"));
              }
              else if (value > 50000000) {
                callback(new Error('最大值不超5千万'))
              } else if (total !== totalMonery.reduce((a, b) => a + b, 0)) {
                callback(new Error('结算金额合计值需等于【项目总金额-首付款】的金额'))

              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        invoice_title: [//发票抬头
          {
            required: true,
            message: "请填写发票抬头",
            trigger: ["blur", "change"],
          }
        ],
        tax_no: [//企业税号
          {
            required: true,
            message: "请填写企业税号",
            trigger: ["blur", "change"],
          }
        ],

        bank_name: [//开户银行
          {
            required: true,
            message: "请填写开户银行",
            trigger: ["blur", "change"],
          }
        ],
        bank_account: [//开户账号
          {
            required: true,
            message: "请填写开户账号",
            trigger: ["blur", "change"],
          }
        ],
        reg_address: [//注册地址
          {
            required: true,
            message: "请填写注册地址",
            trigger: ["blur", "change"],
          }
        ],
        reg_tel: [//注册电话
          {
            required: true,
            message: "请填写注册电话",
            trigger: ["blur", "change"],
          }
        ],
        email: [//邮箱地址
          {
            required: true,
            message: "请填写邮箱地址",
            trigger: ["blur", "change"],
          }
        ],
        receive_name: [//收件人姓名
          {
            required: true,
            message: "请填写收件人姓名",
            trigger: ["blur", "change"],
          }
        ],
        receive_phone: [//手机号
          {
            required: true,
            message: "请填写收件人手机号",
            trigger: ["blur", "change"],
          }, {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error('手机号码格式有误！'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        receive_county_id: [//收货地址
          {
            required: true,
            message: "请选择收货地址",
            trigger: ["blur", "change"],
          }
        ],
        receive_address: [//详细地址
          {
            required: true,
            message: "请填写详细地址",
            trigger: ["blur", "change"],
          }
        ],
      },
      activeVisible: false,
      //预警值设置form
      applicationForm: {
        balance_warning_amount: '',//预警值
        balance_warning_mobile: '',//预警手机号
      },
      applicationFormRule: {
        balance_warning_amount: [
          {
            required: true,
            message: "请填写预警值",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              const money = /^(0|[1-9]\d*)(\.\d{1,2})?$/
              if (value > 100000) {
                callback(new Error('输入最大不超10万'))
              } else if (!RegExp.money.test(value) || !money.test(value) || value == 0) {
                callback(new Error("请输入正确的金额"));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        balance_warning_mobile: [
          {
            required: true,
            message: "请填写预警手机号",
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (!RegExp.mobile.test(value)) {
                callback(new Error('手机号码格式有误！'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
      },
      shopIds: []
    };
  },

  mounted () {
    if (this.$route.params.id) this.id = this.$route.params.id
    if (this.id) {
      API_settingCustomer.getCustomerAll(this.id).then(res => {
        if (!res.invoice_info) {
          res.invoice_info = {
            invoice_type: 1,//发票类型 1:普通电子发票 2:增值税电子发票 3:普通纸质发票 4:增值税纸质发票
            invoice_title: '',
            tax_no: '',
            bank_name: '',
            bank_account: '',
            reg_address: '',//注册地址
            reg_tel: '',//注册电话
            email: '',//邮箱地址
            receive_name: '',//收件人姓名
            receive_phone: '',//收件人手机号
            receive_county_id: '',//收货地址
            receive_address: '',//收货详细地址
            receive_town_id: '',
            receive_town: '',
          }
        }
        if (res.customer_customized_project_dtolist) {
          res.customer_customized_project_dtolist.forEach((item, index) => {
            item.storeList = [];
            this.searchGetStoreList(item.shop_type, index)
            //判断是否已有结算金额
            if (item.customer_customized_project_settlement_dtolist && item.customer_customized_project_settlement_dtolist.some(k => k.settlemented == 1)) {
              item.isDisabled = item.customer_customized_project_settlement_dtolist.length;
              item.isSettlement = true;
              item.original_total_amount = item.total_amount;
            } else {
              item.isSettlement = false;
              item.original_total_amount = item.total_amount;
              item.isDisabled = 1;
            }
            item?.customer_customized_project_settlement_dtolist.forEach((item1, index1) => {
              item1.settlement_date = parseInt(item1.settlement_date * 1000)
            })
          })
        }

        this.formInfo = {
          ...this.formInfo,
          ...res
        }

        if (this.formInfo.customer_customized_project_dtolist.length == 0) {
          this.formInfo.customer_customized_project_dtolist = [
            {
              shop_type: '',//来源应用
              shop_id: '',//商城名称
              total_amount: '',//项目总金额
              down_payment: '',//首付款
              settlement_method: 1,//结算方式
              settlement_times: 1,//项目结算次数
              isDisabled: 1,//默认最小结算次数
              storeList: [],//所选应用列表商城
              customer_customized_project_settlement_dtolist: [
                {
                  settlement_date: '',
                  settlement_amount: ''
                }
              ],//结算日期及金额
              settlement_notify_flag: '',//结算提醒
              settlement_notify_mobile: '',//结算提醒手机号
            }
          ]
        }
        this.applicationForm.balance_warning_amount = this.formInfo.balance_warning_amount
        this.applicationForm.balance_warning_mobile = this.formInfo.balance_warning_mobile

        if (this.formInfo.invoice_info.receive_province_id) {
          this.getAreaHandle_receive("city", { id: this.formInfo.invoice_info.receive_province_id })
          this.getAreaHandle_receive("county", { id: this.formInfo.invoice_info.receive_city_id })
          if (this.formInfo.invoice_info.receive_town_id) {
            this.getAreaHandle_receive("town", { id: this.formInfo.invoice_info.receive_county_id })
          }
        }
        this.isCompanyName = res.name;
        this.files = [{ name: res.contract_document_name, url: res.contract_documents }]
        if (res.image_voucher) {
          res.image_voucher.split(',').forEach(item => {
            this.fileList.push({ name: "", url: item })
          })
        }
      });
    }
    this.getAreaHandle_receive("province");
    this.getShopLv()
  },
  methods: {
    changeAccountPeriod () {
      this.showDisabled = false
      this.formInfo.initial_deposit = 0
    },
    invoiceTypeChange (el) {
      this.formInfo.invoice_type = this.formInfo.invoice_info.invoice_type
    },
    addRedStar (h, { column }) {//表头添加*号
      let _output = [];
      if (['结算日期', '结算金额（元）'].includes(column.label)) {
        _output = [
          h('span', { style: 'color: red' }, '*'),
          h('span', ' ' + column.label)
        ]
      } else {
        _output = [h('span', ' ' + column.label)]
      }
      return _output
    },
    /**判断当前商城名称是否重复 */
    /**1个商城只允许用于1个项目；已被选择到当前客户的项目中的商城，需在下拉框中置灰，不允许再次选择 */
    selectshopName () {
      this.shopIds = this.formInfo.customer_customized_project_dtolist.map(item => item.shop_id)

      this.shopIds.forEach(item => {
        this.formInfo.customer_customized_project_dtolist.forEach(k => {
          k.storeList.forEach(item2 => {
            if (this.shopIds.includes(item2.shop_id)) {
              item2.disabled = true
            } else {
              item2.disabled = false
            }
          })
        })
      })
    },
    async getShopLv () {
      //判断商城权限
      const resStatus = await getShopExtAuth();
      API_Shop.getShopLv().then(res => {
        if (res.data.has_cash_mall === 1) {
          this.storeTypeList.push({ name: "现金商城", value: 2 })
        }
        if (res.data.has_intelligent_card20 === 1) {
          this.storeTypeList.push({ name: "智能套餐卡商城", value: 4 })
        }
        if (resStatus.double_choice_mall_open === "OPEN") {
          this.storeTypeList.push({ name: "双选商城", value: 5 })
        }
        if (resStatus.counting_card_open === "OPEN") {
          this.storeTypeList.push({ name: "小程序商城", value: 6 })
        }
      })
    },
    /**格式化地址数据 */
    dataHandle (data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index],
        };
        returnData.push(data);
      });
      return returnData;
    },

    /**
     * 获取发票寄送区域数据
     * @param type
     * @param data
     */
    getAreaHandle_receive (type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then((res) => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then((res) => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then((res) => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then((res) => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },

    /**
     * 发票寄送地址change事件
     * @param {*} type 
     */
    changeProvince (type) {
      if (type == "province") {
        this.formInfo.invoice_info.receive_city_id = "";//上门取件市id
        this.formInfo.invoice_info.receive_city = "";//上门取件市
        this.formInfo.invoice_info.receive_county_id = "";//上门取件区id
        this.formInfo.invoice_info.receive_county = "";//上门取件区
        this.formInfo.invoice_info.receive_town_id = "";//上门取件镇id
        this.formInfo.invoice_info.receive_town = "";//上门取件镇
        if (this.formInfo.invoice_info.receive_province_id === "") {
          this.formInfo.invoice_info.receive_province = "",//上门取件省
            this.getAreaHandle_receive("province");
        } else {
          const findProvince = this.provinceList.find(item => item.id === this.formInfo.invoice_info.receive_province_id)

          this.formInfo.invoice_info.receive_province = findProvince.name;//上门取件省
          this.getAreaHandle_receive("city", { id: this.formInfo.invoice_info.receive_province_id });
        }
      } else if (type == "city") {
        this.formInfo.invoice_info.receive_county_id = "";//上门取件区id
        this.formInfo.invoice_info.receive_county = "";//上门取件区
        this.formInfo.invoice_info.receive_town_id = "";//上门取件镇id
        this.formInfo.invoice_info.receive_town = "";//上门取件镇
        if (this.formInfo.invoice_info.receive_province_id === "") return;
        if (this.formInfo.invoice_info.receive_city_id !== "") {
          const findCity = this.cityList.find(item => item.id === this.formInfo.invoice_info.receive_city_id)
          this.formInfo.invoice_info.receive_city = findCity.name;//上门取件市
          this.getAreaHandle_receive("county", { id: this.formInfo.invoice_info.receive_city_id });
        }
      } else if (type == "county") {
        this.formInfo.invoice_info.receive_town_id = "";
        if (this.formInfo.invoice_info.receive_city_id === "") return;
        if (this.formInfo.invoice_info.receive_county_id !== "") {
          const findCounty = this.countyList.find(item => item.id === this.formInfo.invoice_info.receive_county_id)
          this.formInfo.invoice_info.receive_county = findCounty.name;//上门取件区
          this.getAreaHandle_receive("town", { id: this.formInfo.invoice_info.receive_county_id });
        }
      } else if (type == "town") {
        this.formInfo.invoice_info.receive_town = "";//上门取件县
        if (this.formInfo.invoice_info.receive_town_id === "") return;
        if (this.formInfo.invoice_info.receive_town_id !== "") {
          const findTown = this.townList.find(item => item.id === this.formInfo.invoice_info.receive_town_id)
          this.formInfo.invoice_info.receive_town = findTown.name;//上门取件县
        }
      }
    },

    /**新增定制项目 */
    addBank () {
      this.formInfo.customer_customized_project_dtolist.push({
        shop_type: '',//来源应用
        shop_id: '',//商城名称
        storeList: [],//所选应用列表商城
        total_amount: '',//项目总金额
        down_payment: '',//首付款
        settlement_method: 1,//结算方式
        settlement_times: 1,//项目结算次数
        isDisabled: 1,//默认最小结算次数
        customer_customized_project_settlement_dtolist: [
          {
            settlement_date: '',
            settlement_amount: ''
          }
        ],//结算日期及金额
        settlement_notify_flag: '',//结算提醒
        settlement_notify_mobile: '',//结算提醒手机号
      })
    },
    /** 删除开户银行 */
    delBank (index) {
      if (this.formInfo.customer_customized_project_dtolist.length > 1) this.formInfo.customer_customized_project_dtolist.splice(index, 1);
      this.selectshopName();
    },
    /**打开余额预警值设置弹窗 */
    editWarningMoney () {
      this.activeVisible = true;
    },
    /**合同上传成功回调 */
    uploadSuccess (response, file, fileList) {
      this.formInfo.contract_documents = response.url;
      this.formInfo.contract_document_name = file.name;
    },
    /**下载合同文件 */
    fileDownload () {
      if (this.formInfo.contract_documents) {
        //创建下载
        let link = document.createElement('a');
        link.download = this.formInfo.contract_document_name
        link.href = this.formInfo.contract_documents
        link.click();
      }
    },
    /**删除合同文件 */
    handleRemove () {
      this.formInfo.contract_documents = '';
      this.formInfo.contract_document_name = '';
    },

    /**图片凭证上传成功回调 */
    imgUploadSuccess (res, file) {

      let obj = {}
      obj['file_type'] = res.ext
      obj['url'] = res.url
      obj['file_name'] = res.name
      this.fileList.push(obj)
      const urlList = this.fileList.map((item, index) => {
        return item.url
      })
      this.formInfo.image_voucher = urlList.toString();
    },
    //图片预览
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //图片删除
    imageHandleRemove (index) {
      this.fileList.splice(index, 1)
      this.formInfo.image_voucher = this.fileList.toString();
    },
    //超出最大上传数量
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 10 个文件`);
    },
    transferConfirm () {
      // 校验预警值设置表单
      this.$refs.applicationForm.validate((valid) => {
        if (valid) {
          this.activeVisible = false;
          this.formInfo.balance_warning_amount = this.applicationForm.balance_warning_amount
          this.formInfo.balance_warning_mobile = this.applicationForm.balance_warning_mobile
          this.$message.success('保存成功')
        } else {
          return false;
        }
      });
    },
    handleChange (item, index) {//项目结算次数增减事件
      if (item.settlement_times > item.customer_customized_project_settlement_dtolist.length) {//增加
        const addNum = item.settlement_times - item.customer_customized_project_settlement_dtolist.length
        for (let i = 0; i < addNum; i++) {
          item.customer_customized_project_settlement_dtolist.push({
            settlement_date: '',
            settlement_amount: ''
          })
        }
      }
      else if (item.settlement_times < item.customer_customized_project_settlement_dtolist.length) {
        item.customer_customized_project_settlement_dtolist.splice(item.settlement_times)
      }
    },
    // 保存
    save () {
      // 校验表单
      this.$refs.formInfo.validate((validate) => {
        if (validate) {
          let formInfo = JSON.parse(JSON.stringify(this.formInfo))
          formInfo.customer_customized_project_dtolist.forEach((item, index) => {
            item.customer_customized_project_settlement_dtolist.forEach((item1, index1) => {
              if (item1.settlement_date != '' && item1.settlement_date != null) {
                item1.settlement_date = parseInt(item1.settlement_date / 1000)
              }
            })
          })

          // 格式校验处理
          // if (this.formate(formInfo)) {
          if (this.id) {//编辑
            API_settingCustomer.editCustomer(
              formInfo
            ).then((res) => {
              this.$message.success("保存成功");
              this.$router.go(-1);
            });
          } else {//新增
            API_settingCustomer.addCustomer(formInfo).then((res) => {
              this.$message.success("保存成功");
              this.$router.go(-1);
            });
          }
          // }
        } else {
          this.$message.error("请完善信息");
        }
      });
    },
    // 表单提交格式校验处理
    formate (formInfo) {
      let arr = true
      return arr
    }
  },
};
</script>
<style lang="scss" scoped>
.page {
  background-color: #fff;

  .buttonbc {
    width: 100%;
    text-align: right;
    float: none;
    height: 55px;

    .back_btn {
      margin: 10px
    }
  }

  h4 {
    background: #90939963;
    margin: 0;
    padding: 10px 10px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  form {
    .el-form-item {
      margin-bottom: 15px;
    }

    .el-radio {
      margin-bottom: 0;
    }
  }
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  border: none;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 90px;
}

.bank-list {
  border: 2px dashed #eee;
  padding: 20px 10px 5px;
  margin-top: 10px;
  position: relative;

  .delBank {
    position: absolute;
    right: -14px;
    top: -14px;
    cursor: pointer;
    font-size: 30px;
  }
}



/deep/ .el-table {
  .el-table__fixed,
  .el-table__fixed-right {
    height: auto !important;
    bottom: 8px;
  }

  th {
    background: #E6ECF7;
    color: #333;
    font-weight: 600;
    line-height: 1;
    padding: 8px 0;
  }

  .cell {
    line-height: 1.2;
  }

  .el-table--border th {
    border-right: 1px solid rgba(220, 223, 230, 1);
  }

  .el-table--border td {
    border-right: 1px solid rgba(220, 223, 230, 1);
  }
}
</style>