import {getStoreList} from '@/api/goodsCategory'
export default {
	
	methods: {
		//选择商城类型
    handleSelectCategory (value,index) {
      console.log(value, index);
      this.formInfo.customer_customized_project_dtolist[index].shop_id = ''
      this.formInfo.customer_customized_project_dtolist[index].shop_name = ''
      this.formInfo.customer_customized_project_dtolist[index].storeList = [];
			let params = {
				mall_type: value
			}
			getStoreList(params).then((response) => {
        this.loading = false
        response.forEach(el => {
          if(this.shopIds.includes(el.shop_id))el.disabled = true
          else el.disabled = false
        });
        this.formInfo.customer_customized_project_dtolist[index].storeList = response
        console.log(this.formInfo.customer_customized_project_dtolist[index].storeList);
			})
    },
    handleShopFocus (value, index) {
      if (this.formInfo.customer_customized_project_dtolist[index].shop_type == '') {
        return this.$message.error("请先选择商城类型");
      }
    },
    searchGetStoreList (mall_type,index) {
      getStoreList({mall_type:mall_type}).then((response) => {
        this.loading = false
        response.forEach(el => {
          if(this.shopIds.includes(el.shop_id))el.disabled = true
          else el.disabled = false
        });
        this.formInfo.customer_customized_project_dtolist[index].storeList = response
        console.log(this.formInfo.customer_customized_project_dtolist[index].storeList);
			})
    },
    handleSelectSell(item) {
      if (!item) return;
      const { id } = item;
      this.formInfo.salesman_id = id;
    },
	}
}
